import React from "react";
import { LeaseTermSelectionTable } from "../LeaseTermSelectionTable";
import {
	ConfigurationRankTypeKind,
	DropdownOption,
	LeaseTermConfigurationFormConfigurations,
	LeaseTermConfigurationFormDivision,
	LeaseTermConfigurationsForm
} from "api";
import utils from "utils";

interface Props {
	bedrooms?: DropdownOption[];
	selectedDivision?: LeaseTermConfigurationFormDivision;
	form: LeaseTermConfigurationsForm;
	onUpdateSelectedDivision: (form: LeaseTermConfigurationsForm) => void;
}

export const LeaseTermSelectionForm: React.FC<Props> = ({ ...props }) => {
	const onDivisionDefaultsChange = (rows: any[]) => {
		if (!props.selectedDivision) {
			return;
		}
		props.onUpdateSelectedDivision({
			...props.form,
			selectedDivision: { ...props.selectedDivision, divisionLeaseTermConfigurations: rows }
		});
	};

	const addDivisionDefaultsRow = function (): void {
		if (!props.selectedDivision) {
			return;
		}

		const newRow: LeaseTermConfigurationFormConfigurations = {
			key: undefined,
			hierarchy: ConfigurationRankTypeKind.Division,
			divisionId: props.selectedDivision.divisionId,
			oneMonth: {},
			twoMonths: {},
			threeMonths: {},
			fourMonths: {},
			fiveMonths: {},
			sixMonths: {},
			sevenMonths: {},
			eightMonths: {},
			nineMonths: {},
			tenMonths: {},
			elevenMonths: {},
			twelveMonths: {},
			thirteenMonths: {},
			fourteenMonths: {},
			fifteenMonths: {},
			sixteenMonths: {},
			seventeenMonths: {},
			eighteenMonths: {},
			nineteenMonths: {},
			twentyMonths: {},
			twentyOneMonths: {},
			twentyTwoMonths: {},
			twentyThreeMonths: {},
			twentyFourMonths: {}
		};

		onDivisionDefaultsChange([newRow]);
	};

	const onBedroomDefaultsChange = (rows: any[]) => {
		if (!props.selectedDivision) {
			return;
		}
		props.onUpdateSelectedDivision({
			...props.form,
			selectedDivision: { ...props.selectedDivision, bedroomLeaseTermConfigurations: rows }
		});
	};

	const addBedroomDefaultsRow = function (): void {
		if (!props.selectedDivision) {
			return;
		}
		const bedroomConfigurations = [...(props.selectedDivision.bedroomLeaseTermConfigurations ?? [])];

		const newRow = utils.cloneObject(
			props.selectedDivision.divisionLeaseTermConfigurations &&
				props.selectedDivision.divisionLeaseTermConfigurations[0]
		);
		if (!newRow) {
			return;
		}
		newRow.key = undefined;
		newRow.hierarchy = ConfigurationRankTypeKind.Bedroom;

		const currentRows = bedroomConfigurations || [];

		onBedroomDefaultsChange(currentRows.concat(newRow));
	};

	const onCountyDefaultsChange = (rows: any[]) => {
		if (!props.selectedDivision) {
			return;
		}
		props.onUpdateSelectedDivision({
			...props.form,
			selectedDivision: { ...props.selectedDivision, countyLeaseTermConfigurations: rows }
		});
	};

	const addCountyDefaultsRow = function (): void {
		if (!props.selectedDivision) {
			return;
		}
		const newRow = utils.cloneObject(
			props.selectedDivision.divisionLeaseTermConfigurations &&
				props.selectedDivision.divisionLeaseTermConfigurations[0]
		);
		if (!newRow) {
			return;
		}
		newRow.key = undefined;
		newRow.hierarchy = ConfigurationRankTypeKind.County;

		const countyConfigurations = [...(props.selectedDivision.countyLeaseTermConfigurations ?? [])];

		onCountyDefaultsChange(countyConfigurations.concat(newRow));
	};

	const onCityDefaultsChange = (rows: any[]) => {
		if (!props.selectedDivision) {
			return;
		}
		props.onUpdateSelectedDivision({
			...props.form,
			selectedDivision: { ...props.selectedDivision, cityLeaseTermConfigurations: rows }
		});
	};

	const addCityDefaultsRow = function (): void {
		if (!props.selectedDivision) {
			return;
		}
		const newRow = utils.cloneObject(
			props.selectedDivision.divisionLeaseTermConfigurations &&
				props.selectedDivision.divisionLeaseTermConfigurations[0]
		);
		if (!newRow) {
			return;
		}
		newRow.key = undefined;
		newRow.hierarchy = ConfigurationRankTypeKind.City;

		const cityConfigurations = [...(props.selectedDivision.cityLeaseTermConfigurations ?? [])];

		onCityDefaultsChange(cityConfigurations.concat(newRow));
	};

	const onPropertyDefaultsChange = (rows: any[]) => {
		if (!props.selectedDivision) {
			return;
		}
		props.onUpdateSelectedDivision({
			...props.form,
			selectedDivision: { ...props.selectedDivision, propertyLeaseTermConfigurations: rows }
		});
	};

	const addPropertyDefaultsRow = function (): void {
		if (!props.selectedDivision) {
			return;
		}
		const newRow = utils.cloneObject(
			props.selectedDivision.divisionLeaseTermConfigurations &&
				props.selectedDivision.divisionLeaseTermConfigurations[0]
		);
		if (!newRow) {
			return;
		}
		newRow.key = undefined;
		newRow.hierarchy = ConfigurationRankTypeKind.Property;

		const propertyConfigurations = [...(props.selectedDivision.propertyLeaseTermConfigurations ?? [])];

		onPropertyDefaultsChange(propertyConfigurations.concat(newRow));
	};

	const tables = () => {
		if (!props.selectedDivision) {
			return <></>;
		}
		return (
			<>
				<>
					<h3>Division</h3>
					<LeaseTermSelectionTable
						onRowChangedAction={onDivisionDefaultsChange}
						onAddRowAction={addDivisionDefaultsRow}
						data={props.selectedDivision.divisionLeaseTermConfigurations}
						beds={props.selectedDivision.bedrooms}
						defaultTable
					/>
				</>
				<>
					<h3>Bedrooms</h3>
					<LeaseTermSelectionTable
						onRowChangedAction={onBedroomDefaultsChange}
						onAddRowAction={addBedroomDefaultsRow}
						data={props.selectedDivision.bedroomLeaseTermConfigurations}
						beds={props.selectedDivision.bedrooms}
					/>
				</>
				<>
					<h3>Counties</h3>
					<LeaseTermSelectionTable
						onRowChangedAction={onCountyDefaultsChange}
						onAddRowAction={addCountyDefaultsRow}
						data={props.selectedDivision.countyLeaseTermConfigurations}
						beds={props.selectedDivision.bedrooms}
						counties={props.selectedDivision.counties}
					/>
				</>
				<>
					<h3>Cities</h3>
					<LeaseTermSelectionTable
						onRowChangedAction={onCityDefaultsChange}
						onAddRowAction={addCityDefaultsRow}
						data={props.selectedDivision.cityLeaseTermConfigurations}
						beds={props.selectedDivision.bedrooms}
						cities={props.selectedDivision.cities}
					/>
				</>
				<>
					<h3>Properties</h3>
					<LeaseTermSelectionTable
						onRowChangedAction={onPropertyDefaultsChange}
						onAddRowAction={addPropertyDefaultsRow}
						data={props.selectedDivision.propertyLeaseTermConfigurations}
						beds={props.selectedDivision.bedrooms}
						properties={props.selectedDivision.properties}
					/>
				</>
			</>
		);
	};

	return <>{tables()}</>;
};
