import React from "react";
import useStyle from "./useStyle";
import { Checkbox as PrimeCheckbox } from "primereact/checkbox";
import ValidationWrapper from "../ValidationWrapper";

export interface CheckboxProps {
	checked: boolean;
	label?: string;
	name?: string;
	onChange: (checked: boolean) => void;
	disabled?: boolean;
	validationMessage?: string;
}

export const Checkbox = React.forwardRef((props: CheckboxProps, ref: React.LegacyRef<PrimeCheckbox>) => {
	const classes = useStyle(props);

	const handlePressEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			props.onChange(!props.checked);
		}
	};

	let containerClasses = classes.checkboxContainer;
	if (props.label) {
		containerClasses = [containerClasses, classes.gap].join(" ");
	}

	return (
		<div className={containerClasses} onKeyDown={handlePressEnter}>
			<ValidationWrapper showErrorState={props.validationMessage != undefined}>
				<PrimeCheckbox
					ref={ref}
					id={props.label}
					name={props.name}
					checked={props.checked !== false}
					onChange={(e) => props.onChange(e.target.checked)}
					tooltip={props.validationMessage}
					tooltipOptions={{ event: "hover" }}
					disabled={props.disabled || false}
				/>
			</ValidationWrapper>
			<label htmlFor={props.label}>{props.label}</label>
		</div>
	);
});
