import { Checkbox } from "components/controls/PricingCheckbox";
import React from "react";
import listUtils from "utils/listUtils";
import useStyle from "./useStyle";
import "./_table.scss";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption } from "api";
import RemoveButton from "components/controls/RemoveButton";
import AddButton from "components/controls/AddButton";
import utils from "utils";
import { Column } from "primereact/column";
import { ColumnDefinition } from "components/controls/createTable";
import { DataTableStandard } from "components/controls/DataTableStandard";
import InputNumber from "components/controls/InputNumber";
import { format, isDate, parseISO, toDate } from "date-fns";
import Calendar, { CalendarType } from "components/controls/Calendar";

interface Props {
	data?: any[];
	beds?: DropdownOption[];
	properties?: DropdownOption[];
	counties?: DropdownOption[];
	cities?: DropdownOption[];
	defaultTable?: boolean;
	onAddRowAction?: () => void;
	onRowChangedAction?: (rows: any[]) => void;
}

export const LeaseTermSelectionTable: React.FC<Props> = ({ data, ...props }) => {
	const classes = useStyle();

	const getDropdownColumn = function (field: string, options: DropdownOption[] | undefined, minWidth: number) {
		return (rowData: any) => {
			const validationFieldName = (field as string).charAt(0).toUpperCase() + (field as string).substring(1);
			const validationMessage =
				rowData["fieldValidationErrors"] && rowData["fieldValidationErrors"][validationFieldName];
			return (
				<Dropdown
					exactPlaceholder={"All"}
					selectedValue={options?.find((x) =>
						rowData[field] && x.key ? utils.stringIsEqualCaseInsensitiveTrimmed(x.key, rowData[field]) : undefined
					)}
					filter
					validationMessage={validationMessage}
					options={options ?? []}
					onChange={(d) => {
						const newDefaultsRow = {
							...rowData
						};
						newDefaultsRow[field] = d?.key;
						onRowChanged(rowData, newDefaultsRow);
					}}
					style={{ minWidth: minWidth, maxWidth: "100%" }}
				/>
			);
		};
	};

	const getDateColumn = function (field: string, required: boolean) {
		return (rowData: any) => {
			const validationFieldName = (field as string).charAt(0).toUpperCase() + (field as string).substring(1);
			const validationMessage =
				rowData["fieldValidationErrors"] && rowData["fieldValidationErrors"][validationFieldName];

			let date: Date | undefined = undefined;
			if (rowData[field] && !isDate(rowData[field])) {
				date = parseISO(rowData[field]);
			} else if (rowData[field]) {
				date = rowData[field];
			}
			return (
				<Calendar
					type={CalendarType.SingleSelection}
					appendTo={document.body}
					value={date}
					showButtonBar
					monthNavigator
					yearNavigator={{ futureYearsToShow: 2 }}
					validationMessage={validationMessage}
					readOnlyInput={false}
					onChange={(d) => {
						const newDefaultsRow = {
							...rowData
						};
						if (d) {
							newDefaultsRow[field] = format(d, "yyyy-MM-dd");
						} else {
							newDefaultsRow[field] = undefined;
						}
						onRowChanged(rowData, newDefaultsRow);
					}}
				/>
			);
		};
	};

	const formatTermColumn =
		<T extends Record<string, any>>(fieldName: keyof T) =>
		(row: T) => {
			const checked = row[fieldName] && (row[fieldName]["enabled"] as boolean);
			const enabledValidationMessage = row["fieldValidationErrors"] && row["fieldValidationErrors"]["Enabled"];

			return (
				<div className={classes.termCell}>
					<Checkbox
						checked={checked === true}
						label=""
						validationMessage={enabledValidationMessage}
						onChange={(checked: any) => {
							const newDefaultsRow = {
								...row
							};
							const value: any = checked === true;
							newDefaultsRow[fieldName]["enabled"] = value;
							onRowChanged(row, newDefaultsRow);
						}}
					/>
					{/* <InputNumber disabled={!checked} className={classes.scalingInput} value={row[fieldName] ? row[fieldName]["scaling"] : undefined} onChange={(value: number) => {
                            const newDefaultsRow = {
                                ...row
                            };
                            newDefaultsRow[fieldName]["scaling"] = value;
                            onRowChanged(row, newDefaultsRow);
                        }}></InputNumber> */}
				</div>
			);
		};

	const addButton =
		props.defaultTable && data?.some((x) => x) ? (
			<></>
		) : (
			<div>
				<div style={{ display: "flex" }}>
					<AddButton title="" onClick={props.onAddRowAction} />
				</div>
			</div>
		);

	const footerRight = <div className={classes.footerButton}>{addButton}</div>;
	const footerLeft = <div className={classes.footerButton}></div>;

	const onRowChanged = (oldRow: any, newRow: any) => {
		if (!data || !props.onRowChangedAction) {
			return;
		}
		newRow.isChanged = true;
		props.onRowChangedAction(listUtils.copyListWithNewItem(data, oldRow, newRow));
	};

	const onRemoveRow = (row: any) => {
		if (!props.onRowChangedAction) {
			return;
		}

		const newData = listUtils.copyListWithItemRemoved(data ?? [], row);
		props.onRowChangedAction(newData);
	};

	const removeRowColumnEditor = (toRemove: any) => {
		if (!props.onRowChangedAction) {
			return <></>;
		}
		if (!toRemove || !data) {
			return <div />;
		}

		return (
			<RemoveButton
				onClick={() => {
					if (toRemove.key) {
						const newRow = { ...toRemove, isDeleted: true };
						onRowChanged(toRemove, newRow);
					} else {
						onRemoveRow(toRemove);
					}
				}}
			/>
		);
	};

	const propertyColumns: ColumnDefinition<any, keyof any>[] = [
		{
			header: "Property",
			field: "propertyId",
			body: getDropdownColumn("propertyId", props.properties, 130),
			filter: false,
			filterFunction: (value, filter) => filterFunction(value, filter, props.properties),
			filterMatchMode: "custom",
			sortable: false,
			className: classes.propertyColumn
		}
	];
	const countyColumns: ColumnDefinition<any, keyof any>[] = [
		{
			header: "County",
			field: "countyId",
			body: getDropdownColumn("countyId", props.counties, 130),
			sortable: false,
			filter: false,
			filterFunction: (value, filter) => filterFunction(value, filter, props.counties),
			filterMatchMode: "custom",
			className: classes.countyColumn
		}
	];
	const cityColumns: ColumnDefinition<any, keyof any>[] = [
		{
			header: "City",
			field: "cityId",
			body: getDropdownColumn("cityId", props.cities, 130),
			sortable: false,
			filter: false,
			filterFunction: (value, filter) => filterFunction(value, filter, props.cities),
			filterMatchMode: "custom",
			className: classes.cityColumn
		}
	];

	const commonColumns: ColumnDefinition<any, keyof any>[] = [
		{ header: "1", body: formatTermColumn("oneMonth"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "2", body: formatTermColumn("twoMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "3", body: formatTermColumn("threeMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "4", body: formatTermColumn("fourMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "5", body: formatTermColumn("fiveMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "6", body: formatTermColumn("sixMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "7", body: formatTermColumn("sevenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "8", body: formatTermColumn("eightMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "9", body: formatTermColumn("nineMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "10", body: formatTermColumn("tenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "11", body: formatTermColumn("elevenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "12", body: formatTermColumn("twelveMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "13", body: formatTermColumn("thirteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "14", body: formatTermColumn("fourteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "15", body: formatTermColumn("fifteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "16", body: formatTermColumn("sixteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "17", body: formatTermColumn("seventeenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "18", body: formatTermColumn("eighteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "19", body: formatTermColumn("nineteenMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "20", body: formatTermColumn("twentyMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "21", body: formatTermColumn("twentyOneMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "22", body: formatTermColumn("twentyTwoMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{ header: "23", body: formatTermColumn("twentyThreeMonths"), sortable: false } as ColumnDefinition<
			any,
			keyof any
		>,
		{ header: "24", body: formatTermColumn("twentyFourMonths"), sortable: false } as ColumnDefinition<any, keyof any>,
		{
			header: "",
			field: "",
			body: removeRowColumnEditor,
			sortable: false,
			className: classes.removeColumn
		} as ColumnDefinition<any, keyof any>
	];

	const filterFunction = (value: any, filter: string, options: DropdownOption[] | undefined) => {
		if (value == null) {
			return true;
		}
		const filterIds = options
			?.filter((x) => x.displayValue?.toLowerCase()?.includes(filter.toLowerCase()))
			.map((x) => x.key);

		return filterIds?.some((filterId) => utils.stringIsEqualCaseInsensitiveTrimmed(filterId ?? "", value ?? ""));
	};

	const secondaryColumns: ColumnDefinition<any, keyof any>[] = [
		{
			header: "Bed",
			field: "bedroomId",
			body: getDropdownColumn("bedroomId", props.beds, 0),
			sortable: false,
			filter: false,
			filterFunction: (value, filter) => filterFunction(value, filter, props.beds),
			filterMatchMode: "custom",
			className: classes.bedColumn
		},
		{
			header: "Start",
			field: "startDate",
			body: getDateColumn("startDate", false),
			sortable: false,
			className: classes.dateColumn
		},
		{
			header: "End",
			field: "endDate",
			body: getDateColumn("endDate", true),
			sortable: false,
			className: classes.dateColumn
		}
	];

	let columns: ColumnDefinition<any, keyof any>[] = [];
	let tableName: string = "LeaseTermSelectionTable";

	let sortField = "bedroomId";

	if (props.properties) {
		columns = columns.concat(propertyColumns);
		tableName = "PropertyLeaseTermSelectionTable";
		sortField = "propertyId";
	}
	if (props.counties) {
		columns = columns.concat(countyColumns);
		tableName = "CountyLeaseTermSelectionTable";
		sortField = "countyId";
	}
	if (props.cities) {
		columns = columns.concat(cityColumns);
		tableName = "CityLeaseTermSelectionTable";
		sortField = "cityId";
	}
	if (!props.defaultTable) {
		columns = columns.concat(secondaryColumns);
	} else {
		tableName = "DivisionDefaultLeaseTermSelectionTable";
		commonColumns.pop();
	}
	columns = columns.concat(commonColumns);

	const cols = columns.filter((c) => c.hidden !== true).map((c, i) => <Column key={i} {...c} />);

	return (
		<DataTableStandard
			data={data?.filter((x) => !x.isDeleted)}
			lazy={false}
			className="lease-configuration-table"
			paginator
			rows={10}
			paginatorLeft={footerLeft}
			paginatorRight={footerRight}
		>
			{cols}
		</DataTableStandard>
	);
};
